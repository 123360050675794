import React, { useState, useEffect, useRef } from 'react';
import { db, collection, query, orderBy, getDocs, limit, onSnapshot, startAfter, addDoc, serverTimestamp } from '../../firebase/firebase-init'; // Adjust the import path as necessary
import './Chat.css'; // Assuming you have some basic CSS
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import moment from 'moment';


function Chat({ sessionId }) {
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastVisible, setLastVisible] = useState(null);
    const [bannedWords, setBannedWords] = useState([]);
    const messagesContainerRef = useRef(null);

    const maxMessages = 20; // Adjust this number based on your container's capacity
    // const bannedWords = ["prout1", "badword2", "badword3"]; // Add your list of banned words here

    // Load the banned words list from the JSON file
    useEffect(() => {
        fetch('/bannedWords.json') // Adjust the path as necessary
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => setBannedWords(data))
            .catch(error => console.error('Error loading banned words:', error));
    }, []);
    

    // Real-time listener for new messages
    useEffect(() => {
        const q = query(collection(db, `sessions/${sessionId}/messages`), orderBy("timestamp", "desc"), limit(maxMessages));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    timestamp: data.timestamp ? data.timestamp.toDate() : new Date() // Ensure conversion to JavaScript Date or use current date
                };
            }).reverse();

            setMessages(newMessages);
            if (snapshot.docs.length > 0) {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
        });

        return () => unsubscribe();
    }, [sessionId]);

    // Fetch older messages for lazy loading
    const fetchOlderMessages = async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        let q = query(collection(db, `sessions/${sessionId}/messages`), orderBy("timestamp", "desc"), startAfter(lastVisible), limit(maxMessages));

        try {
            const documentSnapshots = await getDocs(q);
            const olderMessages = documentSnapshots.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    timestamp: data.timestamp ? data.timestamp.toDate() : new Date() // Ensure conversion to JavaScript Date or use current date
                };
            }).reverse();

            setMessages(prevMessages => [...olderMessages, ...prevMessages]);
            setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
            if (olderMessages.length < maxMessages) {
                setHasMore(false);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (messagesContainerRef.current.scrollTop < 100 && hasMore && !loading) {
                fetchOlderMessages();
            }
        };

        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, loading]);

    const censorMessage = (message) => {
        let censoredMessage = message;
        bannedWords.forEach(word => {
            const regex = new RegExp(`\\b${word}\\b`, 'gi');
            censoredMessage = censoredMessage.replace(regex, '****');
        });
        return censoredMessage;
    };


    const sendMessage = async (e) => {
        e.preventDefault();
        if (message.trim() === '') return;

        const censoredMessage = censorMessage(message.trim());

        const newMessage = {
            username: username.trim() || 'Anonymous',
            text: censoredMessage,
            timestamp: new Date() // Immediate feedback with a new date
        };

        try {
            await addDoc(collection(db, `sessions/${sessionId}/messages`), {
                ...newMessage,
                timestamp: serverTimestamp() // Use Firestore's timestamp for consistency
            });
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-title">Chat</div>
            <div className="messages-container" ref={messagesContainerRef}>
                <TransitionGroup>
                    {messages.map(msg => (
                        <CSSTransition key={msg.id} timeout={500} classNames="message">
                            <div className="message">
                                <strong>{msg.username}: </strong>{msg.text}
                            </div>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
            <form onSubmit={sendMessage} className="send-message-form">
                <input
                    type="text"
                    value={username}
                    id="form-username"
                    onChange={e => setUsername(e.target.value)}
                    placeholder="Your name"
                    className="input-field"
                />
                <input
                    type="text"
                    value={message}
                    id="form-message"
                    onChange={e => setMessage(e.target.value)}
                    placeholder="Type a message..."
                    className="input-field"
                    onKeyPress={e => {
                        if (e.key === 'Enter') sendMessage(e);
                    }}
                />
                <button type="submit" className="send-button"></button>
            </form>
        </div>
    );
}

export default Chat;
