import React, { useEffect, useState } from 'react';
import './Leaderboard.css';
// import Participant from '../Participant/Participant';
import { Flipper, Flipped } from 'react-flip-toolkit';


function Leaderboard({ participants, selectedParticipant, onParticipantSelect }) {


  const [sortedParticipants, setSortedParticipants] = useState([]);

  useEffect(() => {
    // Sort participants by number of push-ups
    const sorted = [...participants].sort((a, b) => a.rank - b.rank);
    setSortedParticipants(sorted);
  }, [participants]);


  return (
    <div className="app-leaderboard">
      <div className="leaderboard-container">
        <h2 className='leaderboard-title'> Leaderboard</h2>
        <Flipper flipKey={sortedParticipants.map(p => p.id).join('')}>
        <ul className="leaderboard-list">
            {sortedParticipants.map((participant) => (
              <Flipped key={participant.id} flipId={participant.id}>
                <li className={`leaderboard-item ${selectedParticipant === participant.id ? 'selected' : ''}`}
                    onClick={() => onParticipantSelect(participant.id)}  // Adding click handler
                >
                  <span className="rank">{participant.rank}</span>
                  <span className="username">{participant.username}</span>
                  <span className="pushups">{participant.pushupsCount}</span>
                </li>
                </Flipped>
            ))}
        </ul>
        </Flipper>
      </div>
    </div>
  );
}

export default Leaderboard;
