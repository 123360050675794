import React, { useState, useEffect, useRef } from 'react';
import Participant from '../Participant/Participant';
import { Flipper } from 'react-flip-toolkit';
import "./SessionActive.css"
import html2canvas from 'html2canvas';
import Counter from '../Counter/Counter';  // Ensure you have the correct path to the Counter component
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase-init';
import MusicPlayer from '../MusicPlayer/MusicPlayer';


const SessionActive = ({ participants, agoraStreams, handleParticipantInteraction, selectedParticipant, startTime, endTime, sessionId, sessionInfo }) => {
  const [shownTooltips, setShownTooltips] = useState({}); // Stores IDs of participants whose tooltips have been shown
  const [numColumns, setNumColumns] = useState(3); // Default to showing 3 cards per row
  const [showDropdown, setShowDropdown] = useState(window.innerWidth >= 1350);
  const contentRef = useRef(null);


const handleColumnChange = (event) => {
    setNumColumns(event.target.value); // Update the number of columns based on user selection
};

useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth < 1350) {
            setShowDropdown(false);
            setNumColumns(2); // Default to 2 columns for smaller screens
        } else {
            setShowDropdown(true);
        }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

// useEffect(() => {
//     // Timer to trigger screenshot 2-3 seconds before session ends
//     const timer = setTimeout(() => {
//       captureScreenshot();
//     }, endTime - new Date().getTime() - 3000); // 3 seconds before endTime

//     return () => clearTimeout(timer);
//   }, [endTime]);

const captureAndShareScreenshot = async () => {
    console.log('Logging capture and share'); // Add this line
    logEvent(analytics, 'website_capture_share_session', {
      name: 'website_capture_share_session',
      content_type: 'button',
    });
    if (contentRef.current) {
        try {
            html2canvas(contentRef.current, {
                onclone: (clonedDoc) => {
                    const clonedNode = clonedDoc.querySelector('.capture-area');
                    clonedNode.style.backgroundColor = '#313E46'; // Set a solid background color
                }
            }).then(canvas => {
                const croppedCanvas = document.createElement('canvas');
                const croppedContext = croppedCanvas.getContext('2d');
                const width = canvas.width;
                const height = canvas.height;
                croppedCanvas.width = width;
                croppedCanvas.height = height;
                croppedContext.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);

                // Moving toBlob inside the then where canvas is defined
                canvas.toBlob(async (blob) => {
                    const file = new File([blob], 'session_screenshot.png', { type: 'image/png' });

                    if (navigator.share) {
                        try {
                            await navigator.share({
                                title: 'Check out this session',
                                text: 'I just completed a great session!',
                                files: [file]
                            });
                            console.log('Share was successful.');
                        } catch (error) {
                            console.error('Error sharing', error);
                            // Fallback download
                            downloadImage(blob);
                        }
                    } else {
                        console.log('Web Share API is not supported in this browser.');
                        // Fallback download
                        downloadImage(blob);
                    }
                }, 'image/png');
            });
        } catch (error) {
            console.error('Screenshot capture failed', error);
        }
    } else {
        console.log('contentRef is null');
    }
};

const downloadImage = (blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'session_screenshot.png';
    a.click();
    URL.revokeObjectURL(url);
};




// const captureScreenshot = () => {
//     if (contentRef.current) {
//         console.log('Capturing screenshot');
//         html2canvas(contentRef.current, {
//           onclone: (clonedDoc) => {
//             const clonedNode = clonedDoc.querySelector('.capture-area');
//             // Directly set the background style
//             clonedNode.style.backgroundColor = '#313E46'; // Example: Set a solid color
//           }
//               }).then(canvas => {
//             const croppedCanvas = document.createElement('canvas');
//             const croppedContext = croppedCanvas.getContext('2d');
//             const width = canvas.width;  // Adjust width if necessary
//             const height = canvas.height; // Adjust height if necessary
//             croppedCanvas.width = width;
//             croppedCanvas.height = height;
//             croppedContext.drawImage(canvas, 0, 0, width, height, 0, 0, width, height);
  
//             canvas.toBlob(blob => {
//                 const url = URL.createObjectURL(blob);
//                 const a = document.createElement('a');
//                 a.href = url;
//                 a.download = 'PushUpChallenge_screenshot.png';
//                 a.click();
//                 URL.revokeObjectURL(url);
//             }, 'image/png');
  
//             console.log('Screenshot captured and download triggered');
//         }).catch(error => {
//             console.error('Screenshot capture failed', error);
//         });
//     } else {
//         console.log('contentRef is null');
//     }
//   };
  

  const handleMouseEnter = (participantId) => {
      if (!shownTooltips[participantId]) {
          setShownTooltips(prev => ({ ...prev, [participantId]: true })); // Mark the tooltip as shown for this participant
      }
  };

  return (
      <div className="participants-list-container">


          <Counter sessionId={sessionId} />

          <div className='settings'>
            {/* <div className='settings-title'>
                Settings
            </div> */}
            <div className='settings-fields'>
                <i className="fas fa-camera capture-icon"onClick={captureAndShareScreenshot}></i>

                <MusicPlayer startTime={startTime} endTime={endTime} />

                {showDropdown && (
                    <div className="dropdown-container">
                        <select id="column-count" value={numColumns} onChange={handleColumnChange}>
                            <option value="2">2 streams per row</option>
                            <option value="3">3 streams per row</option>
                            <option value="4">4 streams per row</option>
                        </select>
                    </div>
                )}
          </div>

            </div>



        <div ref={contentRef} className="capture-area">



          <Flipper flipKey={participants.map(p => p.id).join('')}>

              <ul className="participants-list" style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}>
                  {participants.map(participant => (
                      <li
                          key={participant.id}
                          className={`participant-card ${selectedParticipant === participant.id ? 'selected' : ''}`}
                          onMouseEnter={() => handleMouseEnter(participant.id)}
                          onClick={() => handleParticipantInteraction(participant.id)}
                      >
                          <Participant
                              participant={participant}
                              rankClass={participant.rankClass}
                              stream={agoraStreams[participant.agoraId]}
                          />
                          <div className={`tooltip ${shownTooltips[participant.id] ? 'show' : ''}`}>
                              Track Participant
                          </div>
                      </li>
                  ))}
              </ul>
          </Flipper>
        </div>

      </div>
  );
};

export default SessionActive;
