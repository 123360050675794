import React, { useState, useEffect } from 'react';
import { calculateTimeLeft, formatTimeLeft } from '../../utils/timeUtils';

function TimeLeft({ endTime }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(endTime);
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => clearInterval(timer);  // Cleanup on component unmount
    }, [endTime]);

    return (
        <p className='p-answer'>{timeLeft ? formatTimeLeft(timeLeft) : 'Session Ended'}</p>
    );
}

export default TimeLeft;
