import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Images/logo-gridfyt-noback4.png'; 
import downloadappBlack from '../../Images/Download_on_the_App_Store_Black.svg';
import downloadappWhite from '../../Images/Download_on_the_App_Store_White.svg';
// import logo from '../../Images/logo-gridfyt.jpeg'; 
import "./Header.css"

function Header() {

    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className='header'>
            <Link to="/" className="home-link">
                <img src={logo} alt="Logo" className="logo-image" />
            </Link>
            <div 
                className="about-link"
                onMouseEnter={() => setShowTooltip(true)} 
                onMouseLeave={() => setShowTooltip(false)}
            >
                ABOUT
                {showTooltip && (
                    <span className="tooltip">In construction</span>
                )}
            </div>
            {/* <a href="https://apps.apple.com/gb/app/gridfyt/id6502470351" className="download-link">
                <img src={downloadappWhite} alt="Download on the App Store" className="download-image white" />
                <img src={downloadappBlack} alt="Download on the App Store" className="download-image black" />
            </a> */}
        </div>
    );
}

export default Header;  