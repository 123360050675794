// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Create a separate CSS file for styling
import downloadappWhite from '../../Images/Download_on_the_App_Store_White.svg';
import instaLogo from '../../Images/Instagram_Glyph_Gradient.svg';
import tiktokLogo from '../../Images/TikTok-logo.png';
import tiktokLogoBlack from '../../Images/TikTok-logo-black.png';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        
        <a href="https://apps.apple.com/gb/app/gridfyt/id6502470351" className="app-store-link">
          <img src={downloadappWhite} alt="Download on the App Store" />
        </a>
        <p className="join-us-footer-text">and join us on</p> 
        <div className="social-media-icons">
          {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="/path/to/facebook-icon.png" alt="Facebook" />
          </a> */}
          {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <img src="/path/to/twitter-icon.png" alt="Twitter" />
          </a> */}
          <a href="https://www.instagram.com/gridfytlive?igsh=MXd1MGZlMGxwcXJseA==" target="_blank" rel="noopener noreferrer">
            <img src={instaLogo} alt="Instagram" />
          </a>
          <a href="https://www.tiktok.com/@gridfyt" target="_blank" rel="noopener noreferrer">
            <img src={tiktokLogoBlack} alt="Tiktok" />
          </a>
          {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="/path/to/linkedin-icon.png" alt="LinkedIn" />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
