import React, { useEffect, useState, useRef } from 'react';
import { db, collection, query, where, getDocs, onSnapshot, orderBy, limit, doc, getDoc } from '../../firebase/firebase-init'; // Adjust the import path as necessary
import AgoraRTC from 'agora-rtc-sdk-ng';  // Import Agora SDK
import Participant from '../Participant/Participant';
import Leaderboard from '../Leaderboard/Leaderboard';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Link, useNavigate } from 'react-router-dom';
import Chat from '../Chat/Chat';
import { useParams } from 'react-router-dom';
import logo from '../../Images/dalle_logo.png'
import Header from '../Header/Header';
import SessionActive from '../SessionActive/SessionActive'; // Adjust the import path as necessary
import SessionResults from '../SessionResults/SessionResults';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import MusicPlayer from '../MusicPlayer/MusicPlayer';
import "./SessionManager.css"
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase-init';
import Footer from '../Footer/Footer';




// const sessionId = process.env.REACT_APP_FIREBASE_SESSION_ID
const APP_ID = process.env.REACT_APP_AGORA_APP_ID
const CHANNEL = process.env.REACT_APP_AGORA_CHANNEL


function SessionManager() {
    const { sessionId } = useParams(); // Get session ID from URL
    const navigate = useNavigate();

    const [participants, setParticipants] = useState([]);
    const [sessionInfo, setSessionInfo] = useState({});
    const [sortedParticipants, setSortedParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const agoraClient = useRef(null);
    const [agoraStreams, setAgoraStreams] = useState({});
    const [selectedParticipant, setSelectedParticipant] = useState(null); // State to track the selected participant
    const clientRef = useRef(null);
    // const tooltipRef = useRef(null);
    const [firstHover, setFirstHover] = useState(true); // Track if it's the first hover
    const [tooltipShown, setTooltipShown] = useState(false);
    const contentRef = useRef(null); // Reference to the content area


    AgoraRTC.setLogLevel(3); 
    const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

    // client.setLogLevel(3);  // Set log level to 'NONE'

    useEffect(() => {
      const sessionRef = doc(db, 'sessions', sessionId);

      console.log("last version baby")

      const unsubscribeSession = onSnapshot(sessionRef, (doc) => {
          if (doc.exists()) {
              const sessionData = doc.data();
              setSessionInfo(sessionData);
              setLoading(false);
          } else {
              setError("No such session!");
              setLoading(false);
          }
      }, err => {
          setError("Failed to fetch session data: " + err);
          setLoading(false);
      });

      return () => {
          unsubscribeSession();
      };
  }, [sessionId]);

  useEffect(() => {

        const topParticipantsQuery = query(
          collection(db, `sessions/${sessionId}/participants`),
          orderBy("rank"),
          limit(100)
        );

        const unsubscribe = onSnapshot(topParticipantsQuery, (snapshot) => {
          const newParticipants = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log("heya")
          console.log("participants are", newParticipants);
          newParticipants.forEach(participant => console.log("isbanned", participant.isBanned));
  
          setParticipants(newParticipants);
          setLoading(false);
        }, (err) => {
          console.error("Error fetching participants:", err);
          setError("Failed to fetch participants.");
          setLoading(false);
        });
  
        return () => unsubscribe(); // Cleanup subscription on unmount

    }, [sessionId]);


    useEffect(() => {
      const initializeAgoraClient = async () => {
          if (sessionInfo.isLive && sessionInfo.isActive && !sessionInfo.isFinished) {
              clientRef.current = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
              
              const client = clientRef.current;


              try {
                  // Set the client role to audience with low latency
                  await client.setClientRole('audience', {
                      latencyLevel: 1 // Low latency
                  });
                  await client.join(APP_ID, CHANNEL, null, null);
                  console.log(`Joined channel ${CHANNEL} with low latency`);

                  client.on('user-published', async (user, mediaType) => {
                      if (mediaType === 'video') {
                          await client.subscribe(user, mediaType);
                          console.log('Subscribe success');
                          const remoteVideoTrack = user.videoTrack;
                          setAgoraStreams(prevStreams => ({
                              ...prevStreams,
                              [user.uid]: remoteVideoTrack
                          }));
                      } else if (mediaType === 'audio') {
                          console.log("Audio stream detected but not subscribed.");
                      }
                  });

                  client.on('user-unpublished', (user) => {
                      console.log('Stream removed: ' + user.uid);
                      setAgoraStreams(prevStreams => {
                          const updatedStreams = { ...prevStreams };
                          delete updatedStreams[user.uid];
                          return updatedStreams;
                      });
                  });
              } catch (error) {
                  console.error('Failed to join channel:', error);
                  setError('Failed to join channel');
              }
          }
      };

      initializeAgoraClient();

      return () => {
          if (clientRef.current) {
              clientRef.current.leave().then(() => {
                  console.log('Left the channel');
                  console.log("agoraStreams is" + agoraStreams[0])
              }).catch(err => {
                  console.error('Failed to leave the channel:', err);
              });
          }
      };
  }, [sessionInfo]);

  //might need to replace sessionInfo with nothing??
  
    useEffect(() => {
        
        const checkAndSubscribeStreams = async () => {

          console.log("in checkandsubscribe")
          if (!clientRef.current) return; // Ensure the client is initialized
          const client = clientRef.current;
  
          participants.forEach(participant => {
            const { agoraId } = participant;
            if (agoraStreams[agoraId]) {
              // Stream already subscribed and available
              console.log("stream already subscribed")

              return;
            }
    
            // Check if the user is already published
            client.remoteUsers.forEach(user => {
              if (user.uid === agoraId && user.videoTrack) {
                client.subscribe(user, 'video').then(() => {
                  setAgoraStreams(prevStreams => ({
                    ...prevStreams,
                    [user.uid]: user.videoTrack
                  }));
                }).catch(err => console.error('Subscribe stream failed', err));
              }
            });
          });
        };
    
        checkAndSubscribeStreams();
      }, [participants, agoraStreams]);    

    useEffect(() => {
        // Sort participants by their rank
        const sorted = [...participants].sort((a, b) => a.rank - b.rank);
        setSortedParticipants(sorted);
    }, [participants]);
  
  // useEffect(() => {
  //     // Redirect to 403 page if the session is not live
  //     if (!sessionInfo.isLive && !sessionInfo.isFinished) {
  //       navigate('/403'); // Adjust the path to your 403 page
  //   }
  // }, [sessionInfo, navigate]);




  const toggleSelectedParticipant = (participantId) => {
      setSelectedParticipant(prevSelected => prevSelected === participantId ? null : participantId);
      setFirstHover(false); // Disable tooltip after the first interaction
      console.log('Logging track participant'); // Add this line
      logEvent(analytics, 'website_track_participant', {
        name: 'website_track_participant',
        content_type: 'button',
      });
  
  };

  const handleParticipantInteraction = (participantId) => {
    toggleSelectedParticipant(participantId);
    // Implement additional interaction logic as needed
  };

  const handleTooltipDisplay = () => {
    if (!tooltipShown) setTooltipShown(true);
  };


  function getRankClass(rank) {
      if (rank === 1) return 'first';
      if (rank === 2) return 'second';
      if (rank === 3) return 'third';
      return '';
  }

    
    const renderSessionContent = () => {
      const startTime = sessionInfo.startTime ? sessionInfo.startTime.seconds * 1000 : null; // Convert to milliseconds
      const endTime = sessionInfo.endTime ? sessionInfo.endTime.seconds * 1000 : null; // Convert to milliseconds
      if (sessionInfo.isFinished) {
        return (
          <>
            <div className="page-display">
              <div className="leaderboard-placeholder"></div>  {/* This div acts as a placeholder for the leaderboard */}
              <SessionResults participants={participants} sessionInfo={sessionInfo}/>
              <Chat sessionId={sessionId} />
            </div>
            {/* <Footer /> */}
          </>
        );
      } else {
        return (
          <div className="page-display">
                <Leaderboard 
                    participants={participants} 
                    selectedParticipant={selectedParticipant} 
                    onParticipantSelect={handleParticipantInteraction} 
                />

                <SessionActive
                  participants={participants}
                  agoraStreams={agoraStreams}
                  selectedParticipant={selectedParticipant}
                  sessionId={sessionId}  // Make sure this is being fetched or defined somewhere in SessionComponent
                  startTime={startTime}
                  endTime={endTime}
                  handleParticipantInteraction={handleParticipantInteraction}
                />
              {/* </div> */}

              <Chat sessionId={sessionId} />
          </div>
        );
      }
    };
  
    return (
      <div className="session-container">
        <Header />
        {renderSessionContent()}
      </div>
    );
  }
  
  export default SessionManager;
    
