import { useEffect, useState, useRef } from 'react';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import "./MusicPlayer.css"

const upcomingSong = '/audio/intro.mp3';
const activeSong = '/audio/final_mix_bpm_updated.mp3'
const resultSong = '/audio/intro.mp3';


function MusicPlayer({ startTime, endTime }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSession, setCurrentSession] = useState('upcoming'); // Tracks session state: 'upcoming', 'active', 'result'
  const audioElement = useRef(null);
  const resultAudioRef = useRef(null);
  const upcomingAudioRef = useRef(null);

  useEffect(() => {
    audioElement.current = new Audio(activeSong);
    audioElement.current.addEventListener('canplaythrough', onActiveSongReady);
    resultAudioRef.current = new Audio(resultSong);
    upcomingAudioRef.current = new Audio(upcomingSong);

    // Set up to check the time every second
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      if (now < startTime && currentSession !== 'upcoming') {
        setCurrentSession('upcoming');
        playUpcomingSong();
      } else if (now >= startTime && now <= endTime && currentSession !== 'active') {
        setCurrentSession('active');
        startMusic();
      } else if (now > endTime && currentSession !== 'result') {
        setCurrentSession('result');
        playResultSong();
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
      stopMusic();
      audioElement.current.removeEventListener('canplaythrough', onActiveSongReady);
    };
  }, [currentSession, startTime, endTime]);

  const onActiveSongReady = () => {
    console.log('Active song is ready to play.');
    if (currentSession === 'active' && !isPlaying) {
      startMusic();
    }
  };



  const playUpcomingSong = () => {
    stopMusic();
    upcomingAudioRef.current.loop = true;
    upcomingAudioRef.current.play().catch(e => console.error('Error playing upcoming song:', e));
  };

  const startMusic = () => {
    if (!isPlaying) {
      audioElement.current.currentTime = (Date.now() - startTime) / 1000;
      audioElement.current.play().catch(e => {
        console.error('Error playing active song:', e);
        setIsPlaying(false);  // Ensure UI is updated if play fails
      });
      setIsPlaying(true);  // Assume playing starts successfully
    }
  };

  const playResultSong = () => {
    stopMusic();
    resultAudioRef.current.loop = true;
    resultAudioRef.current.play().catch(e => console.error('Error playing result song:', e));
  };

  const stopMusic = () => {
    audioElement.current.pause();
    audioElement.current.currentTime = 0;
    resultAudioRef.current.pause();
    resultAudioRef.current.currentTime = 0;
    upcomingAudioRef.current.pause();
    upcomingAudioRef.current.currentTime = 0;
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      stopMusic();
    } else {
      determineTrackToPlay();
    }
  };

  const determineTrackToPlay = () => {
    const now = new Date().getTime();
    if (now < startTime) {
      playUpcomingSong();
    } else if (now >= startTime && now <= endTime) {
      startMusic();
    } else if (now > endTime) {
      playResultSong();
    }
  };

  return (
    <div>
      <button onClick={togglePlay} className="player-button">
        {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
      </button>
    </div>
  );
}

export default MusicPlayer;
