import React, { useEffect } from 'react';
import SessionManager from './components/SessionManager/SessionManager'; // Adjust the import path as necessary
import Home from './components/Home/Home';
import HelpPage from './components/HelpPage/HelpPage';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase/firebase-init';

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('Logging page view:', location.pathname + location.search); // Add this line
    logEvent(analytics, 'website_page_view', {
      page_path: location.pathname + location.search,
      page_title: document.title,
    });
  }, [location]);
};

const PageRoutes = () => {
  usePageViews();

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/session/:sessionId" element={<SessionManager />} />
      <Route path="/help" element={<HelpPage />} />
    </Routes>
  );
};

const App = () => (
  <Router>
    <PageRoutes />
  </Router>
);


export default App;
