import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db, collection, getDocs, doc, onSnapshot } from '../../firebase/firebase-init';
import "./Home.css";
import Header from '../Header/Header';
import "../../Colors.css"
import Counter from '../Counter/Counter'
import {calculateTimeLeft} from '../../utils/timeUtils';
import TimeLeft from '../TimeLeft/TimeLeft';
import Footer from '../Footer/Footer';
import downloadappWhite from '../../Images/Download_on_the_App_Store_White.svg';



function Home() {
  const [activeSessions, setActiveSessions] = useState([]);
  const [finishedSessions, setFinishedSessions] = useState([]);
  const [incomingSessions, setIncomingSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextSprintId, setNextSprintId] = useState(null);

  useEffect(() => {
    const now = new Date().getTime();  // Get current timestamp

    const unsubscribe = onSnapshot(collection(db, 'sessions'), async (snapshot) => {
      try {
        const sessionsWithDetails = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const participantsSnapshot = await getDocs(collection(db, `sessions/${docSnapshot.id}/participants`));
          const participants = participantsSnapshot.docs.map(doc => doc.data());

          const winner = participants.find(participant => participant.rank === 1) || { name: 'No winner', pushups: 0 };
          const sessionData = docSnapshot.data();
          const startTimeStamp = sessionData.startTime.seconds * 1000; // Convert to milliseconds
          const endTimeStamp = sessionData.endTime ? sessionData.endTime.seconds * 1000 : null; // Convert to milliseconds
          const duration = sessionData.endTime ? formatDuration(sessionData.startTime, sessionData.endTime) : 'Ongoing';

          return {
            id: docSnapshot.id,
            ...sessionData,
            participantsCount: participantsSnapshot.size,
            duration,
            startTimeStamp,
            endTimeStamp,
            winner: winner.username,
            pushups: winner.pushupsCount || 0
          };
        }));

        setActiveSessions(sessionsWithDetails.filter(session => session.isActive && session.isLive));
        setIncomingSessions(sessionsWithDetails.filter(session => !session.isActive && !session.isFinished && session.startTimeStamp > now).sort((a, b) => a.startTimeStamp - b.startTimeStamp));
        setFinishedSessions(sessionsWithDetails.filter(session => session.isFinished).sort((a, b) => b.endTimeStamp - a.endTimeStamp));
        setLoading(false);
      } catch (err) {
        console.error("Error processing sessions:", err);
        setError("Failed to process sessions.");
        setLoading(false);
      }
    }, (err) => {
      console.error("Error fetching sessions:", err);
      setError("Failed to load sessions.");
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Assuming incomingSessions is already sorted by startTime
    const nextSprint = incomingSessions.find(session => session.isLive);
    if (nextSprint) {
        setNextSprintId(nextSprint.id);
    }
}, [incomingSessions]);


  function formatDuration(startTime, endTime) {
      const durationSeconds = endTime.seconds - startTime.seconds;
      const days = Math.floor(durationSeconds / (3600 * 24));
      const hours = Math.floor((durationSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((durationSeconds % 3600) / 60);
      const seconds = durationSeconds % 60;

      let durationStr = "";
      if (days > 0) durationStr += `${days}d `;
      if (hours > 0) durationStr += `${hours}h `;
      if (minutes > 0) durationStr += `${minutes}m `;
      if (seconds > 0) durationStr += `${seconds}s`;

      return durationStr.trim();  // Trim any extra whitespace at the end
  }

  // Helper function to determine the label and button state
  function getSessionStatus(session) {
    if (session.isLive) {
      if (session.isActive) {
        return { label: 'Livestream', clickable: true };
      } else {
        return { label: 'Livestream', clickable: true };
      }
    } else {
      return { label: 'Not livestream', clickable: false };
    }
  }

  const currentSessionId = activeSessions.length > 0 ? activeSessions[0].id : nextSprintId;

  // Display logic for sessions
  const displaySession = activeSessions.length > 0 ? activeSessions[0] : (incomingSessions.length > 0 ? incomingSessions.find(s => s.id === nextSprintId) : null);


  


//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;


return (
    <div className="home-container">
        <Header />
        <div className="main-section">
            <div className="text-container">
                <h1>Experience the future of fitness</h1>
                <p>Join users across the world in the ultimate AI Powered Fitness Competitions!</p>
                <a href="https://apps.apple.com/gb/app/gridfyt/id6502470351" className="app-store-link-home">
                    <img src={downloadappWhite} alt="Download on the App Store" />
                </a>
            </div>
            {activeSessions.length > 0 ? (
                <div className="active-session">
                    <h3>Join the live session</h3>
                    <Link to={`/session/${activeSessions[0].id}`} className="session-card session-active-card">
                        <div className="session-card-header">
                            <h2>{activeSessions[0].title || 'Unnamed Session'}</h2>
                            <div><i className="fas fa-user-friends"></i> {activeSessions[0].participantsCount}</div>
                            <div className="session-status live-label">Livestream</div>
                        </div>
                        <p className='p-title'>Start</p>
                        <p className='p-answer'>Date: {new Date(activeSessions[0].startTime.seconds * 1000).toLocaleDateString()} | Duration: {activeSessions[0].duration}</p>
                        <p className='p-title'>Time Left</p>
                        <TimeLeft endTime={activeSessions[0].endTime ? activeSessions[0].endTime.seconds * 1000 : null} />
                        <div className="join-now">START &gt;</div>
                    </Link>
                </div>
            ) : incomingSessions.length > 0 ? (
                <div className="upcoming-session">
                    <h3>Get ready for the live session</h3>
                    <Link to={`/session/${incomingSessions[0].id}`} className="session-card session-upcoming-card">
                        <div className="session-card-header">
                            <h2>{incomingSessions[0].title || 'Unnamed Session'}</h2>
                            <div><i className="fas fa-user-friends"></i> {incomingSessions[0].participantsCount}</div>
                            <div className="session-status live-label">Livestream</div>
                            <span className="chevron">&gt;</span>
                        </div>
                        <p className='p-title'>Start</p>
                        <p className='p-answer'>Date: {new Date(incomingSessions[0].startTime.seconds * 1000).toLocaleDateString()}</p>
                        <p className='p-title'>Duration</p>
                        <p className='p-answer'>{incomingSessions[0].duration}</p>
                        <div className="session-upcoming-counter">
                            <Counter sessionId={incomingSessions[0].id} />
                        </div>
                    </Link>
                </div>
            ) : (
                <p>No live or upcoming sessions at the moment.</p>
            )}
        </div>

        <div className="sessions-list">
            <div className='sessions-section-status'>Check the results of the previous sessions</div>
            <div className="session-cards">
                {finishedSessions.map(session => (
                    <Link to={`/session/${session.id}`} key={session.id} className="session-card">
                        <div className="session-card-header">
                            <h2>{session.title || 'Unnamed Session'}</h2>
                            <div><i className="fas fa-user-friends"></i> {session.participantsCount}</div>
                            <div className={`session-status ${session.isLive ? 'live-label' : 'not-live-label'}`}>
                                {session.isLive ? 'Livestream' : 'Not Livestream'}
                            </div>
                            <span className="chevron">&gt;</span>
                        </div>
                        <p className='p-title'>Start</p>
                        <p className='p-answer'>Date: {new Date(session.startTime.seconds * 1000).toLocaleDateString()}</p>
                        <p className='p-title'>Duration</p>
                        <p className='p-answer'>{session.duration}</p>
                        <div className='winner-container-div' style={{marginBottom:'0px'}}>
                            <span className='winner-div'>Winner</span>
                            <div className='winner-user-container-div'>
                                <p className='p-answer' style={{fontWeight:'bold'}}>@{session.winner}</p>
                                <p className='p-answer'>Pushups: {session.pushups}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        <Footer/>
    </div>
);
}

export default Home;
