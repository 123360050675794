import React, { useEffect, useRef, memo } from 'react';
import "./Participant.css"
import { Flipper, Flipped } from 'react-flip-toolkit';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';



const Participant = ({participant, rankClass, stream }) => {
  const videoRef = useRef(null);
  

  useEffect(() => {
    if (stream && videoRef.current) {
      console.log('Playing stream for', participant.agoraId); // Debug log
      stream.play(videoRef.current)

    }
  }, [stream, participant.agoraId]);


  return (
    <Flipped flipId={participant.id}>

                <div className={`participant-card ${rankClass}`}>
                  <div className="participant-card-header">
                      <h2 className="rank">{participant.rank}</h2>
                      <h2 className="username">{participant.username}</h2>
                      <h2 className="pushups">{participant.pushupsCount}</h2>
                  </div>
                  <div className="video-container">
                    {stream ? (
                        <div ref={videoRef} className="video-feed" />
                    ) : (
                        <div className="placeholder">Waiting for video stream...</div>
                    )}
                  </div>             
             </div>
    </Flipped>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.stream === nextProps.stream &&
    prevProps.rankClass === nextProps.rankClass &&
    prevProps.participant.pushupsCount === nextProps.participant.pushupsCount &&
    prevProps.participant.rank === nextProps.participant.rank &&
    prevProps.participant.username === nextProps.participant.username &&
    prevProps.participant.id === nextProps.participant.id // Ensure we compare participant id as well
  );
};

export default memo(Participant, areEqual);
