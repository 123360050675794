import React, {useRef} from 'react';
import './SessionResults.css';
import { FaMedal } from 'react-icons/fa';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase-init';
import html2canvas from 'html2canvas';
import instaLogo from '../../Images/Instagram_Glyph_Gradient.svg';
import tiktokLogo from '../../Images/TikTok-logo.png';


const SessionResults = ({ participants, sessionInfo }) => {
    const topThree = participants.slice(0, 3);
    const medalColors = ['#FFD700', '#C0C0C0', '#CD7F32']; // Gold, Silver, Bronze
    const contentRef = useRef(null);


    const captureAndShareScreenshot = async () => {
        console.log('Logging capture and share'); // Add this line
        logEvent(analytics, 'website_capture_share_results', {
          name: 'website_capture_share_results',
          content_type: 'button',
        });
        if (contentRef.current) {
            console.log('contentRef is not null'); // Add this line
            try {
                console.log('Capturing screenshot'); // Add this line
                html2canvas(contentRef.current, {
                    onclone: (clonedDoc) => {
                        const clonedNode = clonedDoc.querySelector('.capture-area-results'); // Updated to use correct selector
                        clonedNode.style.padding = '20px'; // Set a solid background color
                        clonedNode.style.backgroundColor = '#313E46'; // Set a solid background color
                    }
                }).then(canvas => {
                    console.log('Canvas created'); // Add this line
                    const croppedCanvas = document.createElement('canvas');
                    const croppedContext = croppedCanvas.getContext('2d');
                    const width = canvas.width;
                    const height = canvas.height;
                    croppedCanvas.width = width;
                    croppedCanvas.height = height;
                    croppedContext.drawImage(canvas, 0, 0, width, height+20, 0, 0, width, height);
    
                    // Moving toBlob inside the then where canvas is defined
                    canvas.toBlob(async (blob) => {
                        const file = new File([blob], 'results_screenshot.png', { type: 'image/png' });
    
                        if (navigator.share) {
                            try {
                                await navigator.share({
                                    title: 'Check out this session',
                                    text: 'I just completed a great session!',
                                    files: [file]
                                });
                                console.log('Share was successful.');
                            } catch (error) {
                                console.error('Error sharing', error);
                                // Fallback download
                                downloadImage(blob);
                            }
                        } else {
                            console.log('Web Share API is not supported in this browser.');
                            // Fallback download
                            downloadImage(blob);
                        }
                    }, 'image/png');
                });
            } catch (error) {
                console.error('Screenshot capture failed', error);
            }
        } else {
            console.log('contentRef is null');
        }
    };
    
    const downloadImage = (blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'results_screenshot.png';
        a.click();
        URL.revokeObjectURL(url);
    };
    
    return (


        
        <div className="session-results">

            <div className='feedback-socials'>

                <div className="feedback-link">
                    <a href="https://forms.fillout.com/t/j2BRoVyMsyus" target="_blank" rel="noopener noreferrer">
                        Give your Feedback
                    </a>
                </div>
                <p className="join-us-text">and join us on</p> 
                <a href="https://www.instagram.com/gridfytlive?igsh=MXd1MGZlMGxwcXJseA==" className="social-link">
                    <img src={instaLogo} alt="Instagram logo" className="social-icon" />
                </a>
                <p className="join-us-text">and</p> 
                <a href="https://www.tiktok.com/@gridfyt" className="social-link">
                    <img src={tiktokLogo} alt="TikTok logo" className="social-icon" />
                </a>

            </div>

            <div ref={contentRef} className='capture-area-results'>

                <div className='session-title-results'>
                    <p className='session-title-results-text'>{sessionInfo.title} results</p>  {/* Display the session title */}
                    <button className='capture-button-results' onClick={captureAndShareScreenshot}>
                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                    </button>

                </div>


                <div className="podium">
                    {topThree.map((participant, index) => (
                        <div key={participant.id} className={`podium-place podium-place-${index + 1}`}>
                            <FaMedal className={`medal medal-${index + 1}`} style={{ color: medalColors[index] }} />
                            <span className="podium-pushups">{participant.pushupsCount}</span>
                            <div className="podium-username-wrapper">
                                <span className="podium-username">{participant.username}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="participants-results-list">
                    {participants.map(participant => (
                        <div key={participant.id} className="participant-result">
                            <span>{participant.rank}</span>
                            <span>{participant.username}</span>
                            <span>{participant.pushupsCount}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SessionResults;
