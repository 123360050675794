import React, { useEffect, useState } from 'react';
import { db, doc, onSnapshot } from '../../firebase/firebase-init'; // All from your init module
import './Counter.css';

const Counter = ({ sessionId }) => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [status, setStatus] = useState('Loading...');
    const [time, setTime] = useState('');

    useEffect(() => {
        // Check if sessionId is available before setting up the snapshot
        if (!sessionId) {
            console.log('Session ID is undefined.');
            setStatus('Session ID not provided');
            return;
        }
    
        const docRef = doc(db, 'sessions', sessionId);
    
        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                // Assuming the original data may have excess precision or unusual formatting
                const start = data.startTime.toDate().getTime();
                const end = data.endTime.toDate().getTime();
                setStartTime(start);
                setEndTime(end);
            } else {
                setStatus('No session data available');
            }
        }, error => {
            console.error("Error getting document:", error);
            setStatus('Failed to load session data');
        });
    
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);
    
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
            clearInterval(interval);
        };
    }, [sessionId]); // Make sure sessionId is in the dependency array
    
    useEffect(() => {
        if (startTime && endTime) {
            if (currentTime < startTime) {
                const waitingTime = startTime - currentTime;
                setStatus('Starts in');
                setTime(formatTime(waitingTime));
            } else if (currentTime >= startTime && currentTime <= endTime) {
                const remainingTime = endTime - currentTime;
                setStatus('Time remaining');
                setTime(formatTime(remainingTime));
            } else {
                setStatus('Challenge finished');
                setTime('');
            }
        }
    }, [startTime, endTime, currentTime]);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div className="counter">
            <div className="counter-status">{status}</div>
            <div className="counter-time">{time}</div>
        </div>
    );
};

export default Counter;
