import React from 'react';
import { Link } from 'react-router-dom';
import './HelpPage.css';
import Header from '../Header/Header';


const HelpPage = () => {
    return (
      <div className="help-page-container">

        <Header />
        <div className="setup-section">
          <h2>Section 1: Material</h2>
          <p>iPhone with at least iOS 16.</p>
        </div>
        <div className="setup-section">
          <h2>Section 2: Setup the App</h2>
          <ol>
            <li>Donwload the app from the App Store <a href="https://apps.apple.com/gb/app/gridfyt/id6502470351" target="_blank" rel="noopener noreferrer">here</a>.</li>
            <li>Once downloaded, open the app and Sign Up with your telephone number.</li>
            <li>Allow access to the camera - The camera will help count the pushups and send your stream to the website.</li>
            <li>Join a Live or an Upcoming session from the Home screen.</li>      
          </ol>
        </div>

                  
        <div className="setup-section">

          <h2>Section 3: In the Session</h2>
            <ol>
              <li>Select "Start Pushups".</li>
              <li>Get into your Push Up position.</li>
              <li>Rotate your phone to put it in landscape, about 1-2m away from you (align your body with the figure on the cameraview) and start doing pushups.</li>
              <ol>
                <li><u>In a live session</u>, your pushups will be added to your account.</li>
                <li><u>In an upcoming session</u>, your pushups will be detected but your pushups count will reset to 0 at the start of the session.</li>
              </ol>
              <li><b>Open the website on your laptop </b> and check how you rank versus the others. Good luck to win the session!</li>
            </ol>
        </div>
      </div>
    );
  };
  
export default HelpPage;
